.entry-main {
  display: flex;
  background-size: cover;
  background-blend-mode: lighten;
  color: #231F20;
  height: 480px;
  font-family: Verdana;
}

.carousel-control-next-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='white' stroke='black' stroke-width='0.5' viewBox='0 0 8 8'%3E%3Cpath d='M2.75 0l-1.5 1.5 2.5 2.5-2.5 2.5 1.5 1.5 4-4-4-4z'/%3E%3C/svg%3E");
}

.carousel-control-prev-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='white' stroke='black' stroke-width='0.5' viewBox='0 0 8 8'%3E%3Cpath d='M5.25 0l-4 4 4 4 1.5-1.5-2.5-2.5 2.5-2.5-1.5-1.5z'/%3E%3C/svg%3E");
}

.header-image {
  max-height: 35em;
}

.image-background {
  background-color: #fafddd;
}

.center-title {
  margin: 5rem;
}

.header-image {
  max-width: 100%;
  height: auto;
  margin: auto;
  display: block;
}

.heading {
  margin: 20px 0 20px 0;
  padding: 10px 0 10px 0;
  border-top: 2px solid darkgrey;
  border-bottom: 2px solid darkgrey;
}

h1 {
  font-size: 1rem;
}

.main-heading {
  font-size: 40px;
  text-align: center;
}

.sub-heading {
  font-size: 23px;
  font-style: italic;
  text-align: center;
}

.image-max {
  max-width: 300px;
  max-height: 15em;
}

html {
  font-size: 18px;
}

body {
  font-family: Garamond;
  background-image: url("./assets/bg.jpeg");
  background-repeat: repeat;
  background-size: 50%;
}

.custom-image {
  position: absolute;
  left: 50%;
  transform: translate(-50%);
}

.banner {
  background-color: #FFB40D;
}

@media (min-width: 480px) {
  h1 {
    font-size: 1.3rem;
  }
  .entry-main{
    height: 600px;
  }
  .main-heading {
    font-size: 2.0rem;
  }
  .sub-heading {
    font-size: 1.0rem;
  }
  .image-max {
    max-width: 450px;
  }
}

@media (min-width: 768px) {
  h1 {
    font-size: 1.6rem;
  }
  .entry-main{
    height: 700px;
  }
  .main-heading {
    font-size: 3.0rem;
  }
  .sub-heading {
    font-size: 1.5rem;
  }
}

@media (min-width: 1024px) {
  h1 {
    font-size: 2.2rem;
  }
  .entry-main{
    height: 40rem;
  }
  .main-heading {
    font-size: 5.0rem;
  }
  .sub-heading {
    font-size: 2.5rem;
  }
}

.footer {
  background-color: #001D00;
  font-size: 18px;
  color: #EAEAEA;
}

.footer-header {
  border-bottom: 2px solid #EAEAEA;
  padding: 0px 0 5px 0;
}

.colorless-anchor {
  text-decoration:none;
  color: inherit;
}

.main-image {
  height: auto;
}

.message {
  resize: none;
}

.padding-table-columns td
{
    padding:0 60px 0 0; /* Only right padding*/
}

.carousel-background {
    background-color: #001D00;
}

.image-item {
  height:25rem;
  background-size:cover;
  background-position: center center;
}

.carousel-inner .carousel-item{
    height:15rem;
    background-size:cover;
    background-position: center center;
}

.pointer {
    cursor: pointer;
}

html {
  scroll-padding-top: 60px; /* height of your sticky header */
}

/*
#8E793E - Dark Gold
#AD974F - Light Gold
#231F20 - Dark Blue
#EAEAEA - Light Gray
*/
